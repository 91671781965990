import {symbol} from "prop-types";

$(document).ready(function () {
  $(document).on('click', '.view-notification', function () {
    $.ajax({
      url: "/notifications",
      type: "get",
      dataType: "script",
      data: {}
    })
  })

  $(document).on('click', '.dark-theme-slider', function () {
    lightSelected = $(this).hasClass('lightTheme');
    document.getElementById('themeChange').setAttribute('href', lightSelected ? 'dark' : '');
  });

  $(document).on('click', '.copyUserAddress', function () {
    var copyText = document.getElementById("userAddress");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    toastr.success('Copied successfully.')
  });

  $(document).on("click", ".dashboard-load-more", function (e) {
    $.ajax({
      url: "/category_filter",
      type: "get",
      dataType: "script",
      data: {page_no: $(this).data("page-no"), category: $(this).data("category"), sort_by: $(this).data("sort-by")}
    })
  });

  $(window).scroll(function() {
    if ($(window).scrollTop() == $(document).height() - $(window).height()) {
      $(".dashboard-load-more").click()
      $(".user-load-more").click()
    }
  });

  $(".scrollbar-activity").scroll(function() {
    if ($(".scrollbar-activity").scrollTop() > $(".overall-activities").height() - $(".scrollbar-activity").height()) {
      $(".common-activity-load-more").click()
    }

  })

  $(document).on("click", ".user-load-more", function (e) {
    $.ajax({
      url: "/users/load_tabs",
      type: "get",
      dataType: "script",
      data: {id: $(this).data("id"), page_no: $(this).data("page-no"), tab: $(this).data("tab")}
    })
  });

  $(document).on("click", ".common-activity-load-more", function (e) {
    $.ajax({
      url: "/load_more_activities",
      type: "get",
      dataType: "script",
      data: {id: $(this).data("id"), page_no: $(this).data("page-no"), tab: $(this).data("tab")}
    })
  });

  $(document).on("click", ".dashboard-sort-by", function(e) {
    e.preventDefault()
    var dataParam = {}
    if ($(".top-filter li.active a").data("name")) {
      dataParam["category"] = $(".top-filter li.active a").data("name")
    }

    if ($(this).data("name")) {
      dataParam["sort_by"] = $(this).data("name")
    }

    $.ajax({
      url: "/category_filter",
      type: "get",
      dataType: "script",
      data: dataParam
    })
  })

  $(document).on("click", '.bid-modal-close', function() {
    $('#bid_amt').val('')
    $('#bid_qty').val('')
    $("#biddingForm :input").prop("disabled", false);
  })

  $(document).on("click", '.buy-modal-close', function() {
    $('#buy_qty').val('')
  })

  // easy-autocomplete implementation starts
  $(document).on('click', '#submit-kyc', function(){
    var country = $('#country').val().trim();
    var language = $('#language').val().trim();
    var user_email = $('#user_email').val().trim();
    var user_id = $('#user_id').val();
    if (country === ''){
      return toastr.error('Please select country');
    } else if (language === ''){
      return toastr.error('Please select language');
    } else if (user_email === '') {
      return toastr.error('Please enter your email');
    } else if (!isEmail(user_email)) {
      return toastr.error('Invalid Email Address');
    } else{
      $.ajax({
        url: '/users/' + user_id + '/submit_kyc',
        type: 'POST',
        async: false,
        data: {country: country, language: language, email: user_email },
        success: function (data) {
          if (data.error) {
            toastr.error(data['error'])
          } else {
            console.log('response->', data)
            var verification_url = data['verification_url']
            window.open(verification_url);
          }
        }
      });
    }
  });

  var options = {
    url: "/users/countries.json",

    getValue: function(element) {
      return element.name;
    },

    template: {
      type: "description",
      fields: {
        description: "code"
      }
    },

    list: {
      match: {
        enabled: true
      },

      showAnimation: {
        type: "fade", //normal|slide|fade
        time: 400,
        callback: function() {}
      },

      hideAnimation: {
        type: "slide", //normal|slide|fade
        time: 400,
        callback: function() {}
      }
    },
    maxNumberOfElements: 5,
    theme: "round"
  };
  $("#country").easyAutocomplete(options);

  var language_options = {
    url: "/users/languages.json",

    getValue: "name",

    template: {
      type: "description",
      fields: {
        description: "code"
      }
    },

    list: {
      match: {
        enabled: true
      },
      showAnimation: {
        type: "fade", //normal|slide|fade
        time: 400,
        callback: function() {}
      },

      hideAnimation: {
        type: "slide", //normal|slide|fade
        time: 400,
        callback: function() {}
      }
    },
    theme: "round"
  };
  $("#language").easyAutocomplete(language_options);

  function isEmail(email) {
    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(!regex.test(email)) {
      return false;
    }else{
      return true;
    }
  }
  // easy-autocomplete implementation end
});
